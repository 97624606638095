import { useDispatch, useSelector } from 'react-redux';
import { Dialog } from 'primereact/dialog';
import { DataDialogProps } from '../../../models/data.dialog.props';
import { DialogProps } from '../../../models/dialog.props';

import { useEffect } from 'react';
import { Button } from 'primereact/button';
import { TypeDialog } from '../../../helpers/type_dialog';
import { printPdfActions } from '../actions/item.actions';
import printJS from 'print-js'
import { withTranslation } from 'react-i18next';
// import { usePDFSlick } from "@pdfslick/react";
// import "@pdfslick/core/dist/pdf_viewer.css";
// import "./dialog.print.css"

  function DialogPrintPdf(props: DialogProps<string>) {
    const { t, expand} = props;
    const dispatch = useDispatch();    
    const openPrintPdf  = useSelector((state: any) => state.printPdf.openPrintPdf as DataDialogProps<string>);

   

    const getLink = ()=>{
      return `data:application/pdf;base64,${openPrintPdf.select}`;
    }
    // const { viewerRef, usePDFSlickStore, PDFSlickViewer } = usePDFSlick(
    //   getLink(),
    //   {
    //     singlePageViewer: false,
    //     scaleValue: "page-fit",
    //     removePageBorders:false,

    //   }
    // );

    


    useEffect(() => {
         //setInputs(openPrintPdf?.select)
    }, [openPrintPdf]);


    
    const handleClose = () => {
      dispatch(printPdfActions.openPrintPdf({
        open : false,
        select :"",
        type : TypeDialog.add,
        loading: false,
      }));
    };

    const onDownload = () =>{
      const linkSource = getLink();
      const downloadLink = document.createElement("a");
      const fileName = "reporte.pdf";
      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
    }
    const onPrint = () => {
      printJS({printable: openPrintPdf.select, type: 'pdf', base64: true,   modalMessage:"Cargando"})
    };

    
    const footer = (
        <div>
            <Button loading={openPrintPdf.loading} disabled={openPrintPdf.loading} onClick={onDownload} label={t("download")}  icon="pi pi-download"/>
            <Button loading={openPrintPdf.loading} disabled={openPrintPdf.loading} onClick={onPrint} label={t("print")}  icon="pi pi-print"/>
        </div>
    );

    
    return (
        <Dialog header={t("print") } footer={footer} maximizable maximized={expand} visible={openPrintPdf?.open??false} position="top" onHide={handleClose} 
        breakpoints={{'960px': '75vw', '640px': '100vw'}} 
        style={{width: '45vw'}}
        
        >
        
        <div className='p-fluid grid' style={{height:"100%"}}>  
              <object style={{marginTop:"5px", width:"100%", minHeight:"600px", maxHeight:"100%"}}  data={getLink()} width="100%" height="100%">
                <p>Tu navegador no soporta el plugin PDF, en lugar de visualizarlo puedes
                  <a onClick={()=>{onDownload()}}>click aquí
                para descargar en el PDF.</a></p>
              </object>


              {/* {openPrintPdf.select? 
             
             <div className=" inset-0 bg-slate-200/70 flex flex-col pdfSlick">
             <div className="flex-1  h-full">
               <PDFSlickViewer {...{ viewerRef, usePDFSlickStore, }} className='mt-50' />
           
             </div>
           </div>
            : <></>
            } */}
            </div>
        </Dialog>
    );
  }
    
  export default withTranslation()(DialogPrintPdf);
