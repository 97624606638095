import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { suscriptionActions } from './actions/suscription.actions';
import { DebounceInput } from 'react-debounce-input';
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { SuscriptionHistorical } from './models/suscription-historical';
import { ImageSrc } from '../../components/image-src';
import { TemplatePaginator } from '../../components/TemplatePaginator';
 import Logo from'../../assets/images/logo.png';
import { Button } from 'primereact/button';
import { TypeDialog } from '../../helpers/type_dialog';
import DialogUpdateHistorical from './components/dialog.update-historical';
import { User } from '../settings/users/models/user';
import { withTranslation, WithTranslation } from 'react-i18next';
import { getDefaultDate } from '../../helpers/format';
import { Calendar } from 'primereact/calendar';
import { DataDialogProps } from '../../models/data.dialog.props';
import { Checkbox } from 'primereact/checkbox';
import { CustomerPayLate } from './models/customer-pay-late';

function LateCustomerPage({t}: WithTranslation) {
    const customerPayLate: CustomerPayLate[]= useSelector((state:any) => state.suscription.customerPayLate);
    const loading: boolean= useSelector((state:any) => state.suscription.loading);
    const openHistorical  = useSelector((state: any) => state.suscription.openHistorical as DataDialogProps<CustomerPayLate>);
    const dispatch = useDispatch();
    const [fromDate, setFromDate] = useState<Date>(getDefaultDate());
    const [toDate, setToDate] = useState<Date>(getDefaultDate());
    const [submit, setSubmit] = useState<boolean>(false);
    const user:User  = useSelector((state: any) => state.authentication.user as User);

    
    useEffect(() => {
        if(!openHistorical.open && customerPayLate.length > 0)getLast()
        
   }, [openHistorical]);


    const getLast = ()=>{
        dispatch(suscriptionActions.getCustomerLatePay(fromDate?.toISOString() ?? "", toDate?.toISOString()??"")); 
    }
    const renderHeader = () => {
    return (
        <div className="flex justify-content-between align-items-center">
                <span className="p-input-icon-left" >
                { loading ? <i className="pi pi-spin pi-spinner" />: <i className="pi pi-search" /> }
                    <DebounceInput
                    placeholder={t("search")}
                    minLength={1}
                    style={{width:"100%"}}
                    onChange={event => {
                        if((event.target?.value??"").length > 1){
                            dispatch(suscriptionActions.searchByDescription(event.target.value));
                        }
                    }} 
                    value=""
                    debounceTimeout={400}
                    element={InputText as any} />
            </span>
              
        </div>
    )
}
function handleFromDate(e:any) {
    const { value } = e.target;
    setFromDate(value);
}

function handleToDate(e:any) {
    const {value } = e.target;
    setToDate(value);
}
    const header = renderHeader();

    const showUpdateBtn =  () => {
        
        var permission = user.permissions.find(x=> x.path == "btn_update_suscription");
        return permission;
    }
    const photoBodyTemplate = (suscription: CustomerPayLate)=>{
        return  (suscription.photoUri) ?
            <ImageSrc height="40px" width="45px" src={suscription.photoUri} />
            :<img height="40px" width="40px"src={ Logo || ""} alt="" />
        }

         
    const shouldPayDateBodyTemplate = (row:CustomerPayLate) => {
        if(row.lastSuscription == null) return <span>{t("notApplicable")}</span>
        let date = new Date(row.lastSuscription!);
        return <span>{date?.toLocaleDateString("es-US")}</span>
    }
    const lastAttenLogDateBodyTemplate = (row:CustomerPayLate) => {
        if(row.lastAttenLog == null) return <span>{t("notApplicable")}</span>
        let date = new Date(row.lastAttenLog!);
        return <span>{date?.toLocaleDateString("es-US")}</span>
    }



    const lastPayBodyTemplate = (row:CustomerPayLate) => {
        if(row.startingDate == null) return <span>{t("notApplicable")}</span>
        let date = new Date(row.lastPay!);
        return <span>{date?.toLocaleDateString("es-US")}</span>
    }
    const endingDateBodyTemplate = (row:CustomerPayLate) => {
        if(row.endingDate == null) return <span>{t("notApplicable")}</span>
        let date = new Date(row.endingDate!);
        return <span>{date?.toLocaleDateString("es-US")}</span>
    }
      const actionBodyTemplate = (historical:CustomerPayLate) => {
            const openInNewTab = () => {
                window.open(`https://wa.me/+504${historical.phone}`, '_blank', 'noreferrer');
              };
            const onUpdateSuscription =()=>{
                dispatch(suscriptionActions.openModalUpdateHistorical({
                    open : true,
                    select :historical,
                    type : TypeDialog.edit,
                    loading: false
                }));
            }
          return   <span className="p-buttonset">
                    {/* { historical.phone? <Button  className="p-button-success p-button-outlined" icon="pi pi-whatsapp" iconPos="left" onClick={openInNewTab} /> : <></>} */}
                    {showUpdateBtn()? <Button className="p-button-danger p-button-outlined" icon="pi pi-pencil" iconPos="left" onClick={onUpdateSuscription} />
                    : <></>
                    }
                    
                    {/* <Button label="Ver Todas" icon="pi pi-arrow-right" className="p-button-outlined"  iconPos="right" onClick={showDialogCustomer} /> */}
                </span>
    }
     
      const statusBodyTemplate = (suscription:CustomerPayLate) => {
            if(suscription.daysLeft! <= 0){
                return <div className='suscription-circle red'></div>
            }
            if(suscription.daysLeft! <= 10){
                return <div className='suscription-circle yellow'></div>
            }
          return <div className='suscription-circle green'></div>
    }
     
      const doneBodyTemplate = (suscription:CustomerPayLate) => {
        const handleChangeDone = (e:any)=>{
            dispatch(suscriptionActions.markCustomerLatePay({
                checked: e.checked,
                suscriptionId:  suscription.suscriptionId!,
                 customerId: suscription.customerId!,
                 fromDate: fromDate?.toISOString(),
                 toDate: toDate?.toISOString()
            } ));
        }
        
        return <span className="p-float-label p-input-icon-left">
            <Checkbox inputId="cbActive" name="active" disabled={!showUpdateBtn()} value={suscription?.done??false} onChange={handleChangeDone} checked={suscription?.done??false}></Checkbox>
        </span>
    }

    return (

            <div className="p-fluid grid  mt-1">
                <div className=" col-12 card">

                        <div className="p-fluid grid">
                            <div className="field col-2">  
                                <label htmlFor="fromDate" className="block">{t("fromDate")}</label>
                                <Calendar id="fromDate" value={fromDate!== undefined ?  new Date(fromDate??"") :undefined}  onChange={handleFromDate}></Calendar>
                                {(submit&& !fromDate) ?<small id="name-help" className="block p-error">Seleccione la fecha Inicial</small>: <></> }           
                            </div>
                            <div className="field col-2">
                                <label htmlFor="toDate" className="block">{t("toDate")}</label>
                                <Calendar id="toDate" value={toDate!== undefined ?  new Date(toDate??"") :undefined} onChange={handleToDate}></Calendar>
                                {(submit&& !toDate) ?<small id="name-help" className="block p-error">Seleccione la fecha final</small>: <></> }
                            </div>

                            <div className=" col-2 mt-5">
                                <Button label={t("search")} onClick={getLast}  icon="pi pi-search" iconPos="left" data-testid="btn-search" />
                            </div> 
                        
                
                        </div>


                    {/* {header} */}
                    <DataTable  emptyMessage={t("noResultFound")} paginatorTemplate={TemplatePaginator as any} 
                        loading={loading} paginator rows={10}  disabled={loading}
                        value={customerPayLate??[]} responsiveLayout="scroll"
                        size="small" stripedRows  >
                        <Column filter  field="photoId" header={t("photo")} body={photoBodyTemplate}></Column>
                        <Column filter filterType="contains" field="customerId" header={t("customerId")}></Column>
                        <Column filter filterType="contains" field="customer" header={t("customer")}></Column>
                        {/* <Column filter filterType="contains" field="phone" header={t("phone")}></Column> */}
                        <Column sortable  field="lastPay" header={t("lastPay")} body={lastPayBodyTemplate}></Column>
                        <Column sortable  field="lastSuscription" header={t("shouldPayDate")} body={shouldPayDateBodyTemplate}></Column>
                        <Column sortable field="endingDate" header={t("endingDate")} body={endingDateBodyTemplate} ></Column>
                        <Column sortable  field="lastAttenLog" header={t("lastAttenLog")} body={lastAttenLogDateBodyTemplate}></Column>
                        {/* <Column sortable  field="daysLeft" header={t("leftDays")}></Column> */}
                        <Column header={t("status")} body={statusBodyTemplate} />
                        <Column header={t("done")} body={doneBodyTemplate} />
                        <Column header={t("action")} body={actionBodyTemplate} />
                    </DataTable>
                </div>
                <DialogUpdateHistorical onClose={getLast} ></DialogUpdateHistorical>
            </div>
 
       
    );
}


    
export default withTranslation()(LateCustomerPage);
